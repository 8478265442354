"use client"

import { useEffect } from "react"
import Image from "next/image"
import { Button } from "@/components/ui/button"
import { RefreshCw } from "lucide-react"

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error("Global error:", error)
  }, [error])

  return (
    <html lang="en">
      <body>
        <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
          <div className="w-full max-w-md bg-white rounded-lg shadow-sm overflow-hidden">
            <div className="bg-red-900 p-4 text-white text-center">
              <h1 className="text-xl font-bold">Critical Error</h1>
            </div>

            <div className="p-6 flex flex-col items-center">
              <div className="w-20 h-20 rounded-full bg-gray-100 flex items-center justify-center mb-6">
                <Image src="/discord-logo.png" alt="Discord Logo" width={40} height={40} className="rounded-full" />
              </div>

              <h2 className="text-xl font-semibold mb-2 text-center">Something went seriously wrong</h2>
              <p className="text-gray-500 mb-6 text-center">
                We've encountered a critical error. Please refresh the page to try again.
              </p>

              <Button onClick={reset} className="flex items-center gap-2">
                <RefreshCw className="h-4 w-4" />
                Refresh Application
              </Button>
            </div>
          </div>
        </div>
      </body>
    </html>
  )
}

